/* styles.css */

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

* {
  box-shadow: none !important;
}

.card-container {
  margin-top: 20px;
}

.error-message {
  color: #ff0033;
  background-color: #ffe6e6;
  border: 1px solid #ff0033;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.error-icon {
  margin-right: 10px;
}

@media print
{    
    button, .header, .footer
    {
        display: none !important;
    }
}